import React from "react";

import ProductCard from "../../../components/ProductCards/ProductCards";
import SEO from "../../../components/SEO/Seo";

import Burdeos from "../../../data/burdeos";
import WineBottle from "../botellas_vino";

const BurdeosView = () => {
  return (
    <>
      <SEO
        title="Blue Sky SA - Botellas de Vidrio. Burdeos"
        keywords="Blue Sky SA , Botellas , Botellas de vidrio , Tapas rosca , Corchos , Tapones , Argentina , Mendoza , Tapas Pilfer , Screw Caps , Aluminio.  - Botellas de vidrio . Burdeos, Rhoden, Rielsling, Rhin, Flauta o Jerezana. Alta, baja, con picada, sin picada. 187, 250, 375, 500 y 750 ml. Color verde, ambar, marrón y blanco. Boca corcho, tapón sintético o screw caps. Mendoza - Argentina."
        description=" Encuentra botellas Burdeos y Burdeos eco de vidrio en Blue Sky SA.  - Botellas de vidrio . Burdeos, Rhoden, Rielsling, Rhin, Flauta o Jerezana. Alta, baja, con picada, sin picada. 187, 250, 375, 500 y 750 ml. Color verde, ambar, marrón y blanco. Boca corcho, tapón sintético o screw caps. Mendoza - Argentina."
      />
      <WineBottle subtitle="Burdeos">
        <ProductCard data={Burdeos} />
      </WineBottle>
    </>
  );
};

export default BurdeosView;
