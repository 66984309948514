import {
  Burdeos187,
  Burdeos375,
  Burdeos375Corcho,
  Leyda375,
  ConicaAlta375,
  ConicaAlta375Corcho,
  ScrewCap,
  Burdeos500,
  Maule750,
  Loncomilla750,
  Loncomilla750Corcho,
  Malvilla750,
  Limari700,
  Limari750,
  Limari750Corcho,
  Limari750Barrosa,
  ConicaAlta750,
  ConicaAlta750Corcho,
  ConicaBaja750,
  Colchagua750,
  Lontue750,
  Rapel750,
  Altamira750,
  BurdeosTop750,
  Maipo750,
  SantaCruz750,
  Sommelier750,
  Burdeos1000,
  Burdeos1500TR,
  Burdeos1500Corcho,
  Burdeos1500CorchoB,
  BurdeosMagnum1500,
  Bordolese3000,
  BordolesePesante5000,
  Burdeos6000,
  Burdeos187Modal,
  Burdeos375Modal,
  Burdeos375CorchoModal,
  ConicaAlta375Modal,
  ConicaAlta375CorchoModal,
  ScrewCapModal,
  Burdeos500Modal,
  Maule750Modal,
  Loncomilla750Modal,
  Loncomilla750CorchoModal,
  Malvilla750Modal,
  Limari700Modal,
  Limari750Modal,
  Limari750CorchoModal,
  ConicaAlta750Modal,
  ConicaAlta750CorchoModal,
  ConicaBaja750Modal,
  Colchagua750Modal,
  Lontue750Modal,
  Rapel750Modal,
  Altamira750Modal,
  BurdeosTop750Modal,
  Maipo750Modal,
  SantaCruz750Modal,
  Sommelier750Modal,
  Burdeos1000Modal,
  Burdeos1500TRModal,
  Burdeos1500CorchoModal,
  Burdeos1500CorchoBModal,
  BurdeosMagnum1500Modal,
  Bordolese3000Modal,
  BordolesePesante5000Modal,
  Burdeos6000Modal,
  SeparadorXL,
} from "../images/productos/botella_vino/burdeos";

import {
  PDF1,
  PDF2,
  PDF3,
  PDF5,
  PDF6,
  PDF7,
  PDF8,
  PDF9,
  PDF10,
  PDF11,
  PDF12,
  PDF13,
  PDF14,
  PDF15,
  PDF16,
  PDF17,
  PDF18,
  PDF19,
  PDF20,
  PDF21,
  PDF22,
  PDF23,
  PDF24,
  PDF25,
  PDF26,
  PDF27,
  PDF28,
  PDF29,
  PDF32,
  PDF33,
  PDF34,
  PDF35,
  PDF36,
  PDF37,
} from "../../static/04-burdeos/index";


import { BurdeosEco } from "./burdeos_eco"
const wineBottles = [
  {
    pdfDownload: PDF1,
    size: 187,
    name: "Burdeos 187",
    bottledata: {
      subtitle: "Burdeos 187",
      description: "187.5 ML - SCREW CAP",
    },
    src: Burdeos187,
    srcModal: Burdeos187Modal,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    pdfDownload: PDF2,
    size: 375,
    name: "Burdeos 375",
    bottledata: {
      subtitle: "Burdeos 375",
      description: "375 ML - P.12 - SCREW CAP",
    },
    src: Burdeos375,
    srcModal: Burdeos375Modal,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    pdfDownload: PDF3,
    size: 375,
    name: "Burdeos 375",
    bottledata: {
      subtitle: "Burdeos 375",
      description: "375 ML - P.12 - CORCHO",
    },
    src: Burdeos375Corcho,
    srcModal: Burdeos375CorchoModal,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    pdfDownload: PDF7,
    size: 500,
    name: "Burdeos 500",
    bottledata: {
      subtitle: "Burdeos 500",
      description: "500 ML - P.15 - SCREW CAP",
    },
    src: ScrewCap,
    srcModal: ScrewCapModal,
    color: {
      white: true,
      green: false,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    pdfDownload: PDF8,
    size: 500,
    name: "Burdeos 500",
    bottledata: {
      subtitle: "Burdeos 500",
      description: "500 ML - P.15 - CORCHO",
    },
    src: Burdeos500,
    srcModal: Burdeos500Modal,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    pdfDownload: PDF12,
    size: 750,
    name: "Malvilla 750",
    bottledata: {
      subtitle: "Malvilla 750",
      description: "750 ML - P.18 - CORCHO",
    },
    src: Malvilla750,
    srcModal: Malvilla750Modal,
    color: {
      white: true,
      green: false,
      darkGreen: false,
      yellow: true,
      lightGreen: false,
    },
  },
  {
    pdfDownload: PDF17,
    size: 750,
    name: "Conica Baja 750",
    bottledata: {
      subtitle: "Conica Baja 750",
      description: "750 ML - P.29 - CORCHO",
    },
    src: ConicaBaja750,
    srcModal: ConicaBaja750Modal,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    pdfDownload: PDF19,
    size: 750,
    name: "Lontue 750",
    bottledata: {
      subtitle: "Lontue 750",
      description: "750 ML - P.30 - CORCHO",
    },
    src: Lontue750,
    srcModal: Lontue750Modal,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    pdfDownload: PDF20,
    size: 750,
    name: "Rapel 750",
    bottledata: {
      subtitle: "Rapel 750",
      description: "750 ML - P.36 - CORCHO",
    },
    src: Rapel750,
    srcModal: Rapel750Modal,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    pdfDownload: PDF21,
    size: 750,
    name: "Altamira 750",
    bottledata: {
      subtitle: "Altamira 750",
      description: "750 ML - P.40 - CORCHO",
    },
    src: Altamira750,
    srcModal: Altamira750Modal,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    pdfDownload: PDF22,
    size: 750,
    name: "Burdeos Top 750",
    bottledata: {
      subtitle: "Burdeos Top 750",
      description: "750 ML - P.40 - CORCHO",
    },
    src: BurdeosTop750,
    srcModal: BurdeosTop750Modal,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    pdfDownload: PDF24,
    size: 750,
    name: "Santa Cruz 750",
    bottledata: {
      subtitle: "Santa Cruz 750",
      description: "750 ML - P.50 - CORCHO",
    },
    src: SantaCruz750,
    srcModal: SantaCruz750Modal,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  ...BurdeosEco,
  {
    pdfDownload: PDF26,
    size: 1000,
    name: "Burdeos 1000",
    bottledata: {
      subtitle: "Burdeos 1000",
      description: "1000 ML - P.12 - CORCHO",
    },
    src: Burdeos1000,
    srcModal: Burdeos1000Modal,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  { src: SeparadorXL, divider: true },

  {
    pdfDownload: PDF36,
    size: 1500,
    name: "Burdeos 1500",
    bottledata: {
      subtitle: "Burdeos 1500",
      description: "1500 ML - P.17 - SCREW CAP",
    },
    src: Burdeos1500TR,
    srcModal: Burdeos1500TRModal,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    pdfDownload: PDF27,
    size: 1500,
    name: "Burdeos 1500",
    bottledata: {
      subtitle: "Burdeos 1500",
      description: "1500 ML - P.17 - CORCHO",
    },
    src: Burdeos1500Corcho,
    srcModal: Burdeos1500CorchoModal,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    pdfDownload: PDF29,
    size: 1500,
    name: "Burdeos Magnum 1500",
    bottledata: {
      subtitle: "Burdeos Magnum",
      description: "1500 ML - P.35 - CORCHO",
    },
    src: BurdeosMagnum1500,
    srcModal: BurdeosMagnum1500Modal,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },


  {
    pdfDownload: PDF32,
    size: 3000,
    name: "Bordolese IT.3000",
    bottledata: {
      subtitle: "Bordolese IT.3000",
      description: "3000 ML - P.8 - CORCHO",
    },
    src: Bordolese3000,
    srcModal: Bordolese3000Modal,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    pdfDownload: PDF33,
    size: 5000,
    name: "Bordolese IT. Pesante 5000",
    bottledata: {
      subtitle: "Bordolese IT.",
      description: "5000 ML - P.9,5 - CORCHO",
    },
    src: BordolesePesante5000,
    srcModal: BordolesePesante5000Modal,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    pdfDownload: PDF37,
    size: 6000,
    name: "Bordolese Francese 6000",
    bottledata: {
      subtitle: "Bordolese FR. 6000",
      description: "6000 ML - P.40 - CORCHO",
    },
    src: Burdeos6000,
    srcModal: Burdeos6000Modal,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
];

export default wineBottles;
